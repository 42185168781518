<template>
  <Toast />
  <div
    class="surface-card p-4 shadow-2 border-round w-full lg:w-6"
    style="margin: auto"
  >
    <div class="text-center mb-5">
      <img
        :src="publicPath + 'images/logo-white.svg'"
        alt="Image"
        height="100"
      />
      <div class="text-900 text-3xl font-medium mb-3">Recupera tu cuenta</div>
      <!--
      <span class="text-600 font-medium line-height-3"
        >¿No tienes una cuenta?</span
      >
      <router-link
        class="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
        to="/signup"
        >¡Créala hoy!</router-link
      >-->
    </div>

    <div>
      <label for="email" class="block text-900 font-medium mb-2">Email</label>
      <InputText
        id="email"
        v-model="email"
        type="text"
        class="w-full mb-3"
        disabled
      />
      <label for="password" class="block text-900 font-medium mb-2"
        >Contraseña</label
      >
      <InputText
        id="password"
        v-model="password"
        type="password"
        class="w-full mb-3"
      />
      <label for="password2" class="block text-900 font-medium mb-2"
        >Repetir contraseña</label
      >
      <InputText
        id="password2"
        v-model="passwordCopy"
        type="password"
        class="w-full mb-3"
      />
      <div class="flex align-items-center justify-content-between mb-6">
        <span class="p-invalid" v-if="errors.password">{{
          errors.password
        }}</span>
      </div>

      <Button
        label="Cambiar contraseña"
        :loading="loading"
        icon="pi pi-user"
        @click="sendCode()"
        class="w-full"
      ></Button>
    </div>
  </div>
</template>

<script>
import MerchantService from '../../service/MerchantService';

export default {
  data() {
    return {
      publicPath: process.env.BASE_URL,
      email: this.$route.query?.email,
      code: this.$route.query?.code,
      merchantService: null,
      password: '',
      passwordCopy: '',
      errors: {},
      loading: false,
    };
  },
  created() {
    this.merchantService = new MerchantService();
  },
  watch: {
    password() {
      this.validatePassword();
    },
    passwordCopy() {
      this.validatePassword();
    },
  },
  methods: {
    async sendCode() {
      this.validatePassword();
      if (this.errors.password) return;
      this.loading = true;
      try {
        await this.merchantService.changePassword(
          this.email,
          this.password,
          this.code
        );
        this.$toast.add({
          severity: 'success',
          summary: 'Contraseña actualizada',
          detail: 'La contraseña se ha actualizado con éxito',
          life: 3000,
        });
        this.$router.push('/');
      } catch (error) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error cambiando la contraseña',
          detail:
            'No se ha podido cambiar la contraseña, por favor inténtelo más tarde',
          life: 3000,
        });
      }
      this.loading = false;
    },
    validatePassword() {
      if (this.password.length < 6) {
        this.errors.password = 'La contraseña es demasiado corta';
        return;
      }
      if (this.password.length > 50) {
        this.errors.password = 'La contraseña es demasiado larga';
        return;
      }
      if (this.password !== this.passwordCopy) {
        this.errors.password = 'Las contraseñas no coinciden';
        return;
      }
      this.errors.password = null;
    },
  },
};
</script>
